import type { RichtextStoryblok } from '@/types/component-types-sb'
import { useStoryblokApi } from '@storyblok/vue'

export const renderRichtext = (text: string | RichtextStoryblok): string => {
    const storyblokApi = useStoryblokApi()

    // Loading Storyblok custom components
    storyblokApi.setComponentResolver((component, blok) => {
        switch (component) {
            case 'button': {
                return `<StoryblokButton :button='${JSON.stringify(blok)}' />`
            }

            case 'video': {
                return `<Video :blok='${JSON.stringify(blok)}' class="px-0" />`
            }

            case 'one-column': {
                return `<OneColumn :blok='${JSON.stringify(blok)}' />`
            }

            case 'two-columns': {
                return `<TwoColumns :blok='${JSON.stringify(blok)}' />`
            }

            case 'link-list': {
                return `<LinkList :blok='${JSON.stringify(blok)}' />`
            }

            case 'spacer': {
                return `<Spacer :blok='${JSON.stringify(blok)}' />`
            }

            case 'profile-button': {
                return `<ProfileButton :blok='${JSON.stringify(blok)}' />`
            }

            default: {
                return `Component ${component} not found`
            }
        }
    })

    const renderedHtml = storyblokApi.richTextResolver.render({
        content: [text],
    })

    // Remove inline styles
    const cleanedHtml = renderedHtml.replace(/ style="[^"]*"/g, '')

    return cleanedHtml
}
